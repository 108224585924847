import { Box, Text } from '@theme-ui/components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ContentBox } from 'components/Common/ContentBox';
import { Icon } from 'components/Common/Icon';
import { Submit } from 'components/FormInputs/Submit';
import { Checkbox } from 'components/FormInputs/Checkbox';
import { Signature } from 'components/FormInputs/Signature';
import { useUploadSignature } from 'pages/Acceptance/hooks/useUploadSignature';
import { useConfirmQuote } from 'pages/Acceptance/hooks/useConfirmQuote';
import { agreeTermsValidation } from 'utils/helpers';

export const Confirmation = ({ children, userId, quoteId, ...props }) => {
    const uploadSignature = useUploadSignature();
    const confirmQuote = useConfirmQuote();

    const checkboxTermsLink = () => (
        <>
            <Text
                as="a"
                sx={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 400 }}
                onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.easifleet.com.au/terms-conditions/', '_blank');
                }}
            >
                Terms and Conditions
            </Text>
            *
        </>
    );

    return (
        <Box sx={{ textAlign: 'center', mb: 5 }}>
            <Formik
                enableReinitialize
                initialValues={{
                    user_uuid: userId,
                    signature: false,
                    agree_terms: false,
                }}
                validationSchema={Yup.object({
                    agree_terms: agreeTermsValidation,
                })}
                validateOnMount={true}
                onSubmit={async ({ user_uuid, signature, ...values }) => {
                    // upload signature first
                    const sigUpload = await uploadSignature.mutateAsync({
                        user_uuid,
                        signature,
                    });

                    // send remaining data if sig upload successful
                    if (sigUpload.success) {
                        await confirmQuote.mutateAsync({
                            quoteUuid: quoteId,
                            ...values,
                        });
                    }
                }}
            >
                {() => (
                    <>
                        <Form>
                            <ContentBox sx={{ py: 5, mb: 4 }} {...props}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        mx: 'auto',
                                        maxWidth: 330,
                                    }}
                                >
                                    <Icon icon="pen-circle" mb={2} size="75px" />

                                    <Text as="p" variant="lead" mb={4}>
                                        Please sign to confirm this quote and proceed to the next step.
                                    </Text>

                                    <Signature name="signature" placeholder="Signature" mb={4} />

                                    <Checkbox id="agree_terms" name="agree_terms" label="I acknowledge that I have read and agree to the " checkboxTermsLink={checkboxTermsLink} sx={{ textAlign: 'left' }} />
                                </Box>
                            </ContentBox>

                            <Submit text="Accept Quote & Begin Application" submittingText="Submitting..." />
                        </Form>
                    </>
                )}
            </Formik>
        </Box>
    );
};
