import * as Yup from 'yup';
import moment from 'moment';

const phoneValidation = Yup.string()
    .required('Required')
    .matches(
        // eslint-disable-next-line
        /^([\+\-\(\)0-9\s]){8,}$/i,
        'Incorrect format'
    );
const pidRequiredMessage = 'Please use the address finder to ensure your address is valid. Start typing to see suggestions.';

export let formData = [
    {
        stage: 'Novated Lease Application',
        sections: [
            {
                title: 'Personal Details',
                groups: [
                    {
                        title: 'Your details',
                        groupFieldName: 'personal',
                        fields: [
                            [
                                {
                                    label: 'Title',
                                    name: 'title',
                                    type: 'select',
                                    options: [
                                        { label: 'Capt', value: 'Capt' },
                                        { label: 'Dr', value: 'Dr' },
                                        { label: 'Miss', value: 'Miss' },
                                        { label: 'Mr', value: 'Mr' },
                                        { label: 'Mrs', value: 'Mrs' },
                                        { label: 'Ms', value: 'Ms' },
                                        { label: 'Mx', value: 'Mx' },
                                        { label: 'Prof', value: 'Prof' },
                                        { label: 'Rev', value: 'Rev' },
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'First Name',
                                    name: 'first_name',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Last Name',
                                    name: 'last_name',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Date of Birth',
                                    name: 'date_of_birth',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'Gender',
                                    name: 'gender',
                                    type: 'select',
                                    options: [
                                        { label: 'Female', value: 'Female' },
                                        { label: 'Male', value: 'Male' },
                                        { label: 'Other', value: 'Other' },
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'No. of Dependents',
                                    name: 'dependents',
                                    type: 'select',
                                    options: [
                                        { label: 'No dependents', value: 0 },
                                        { label: '1', value: 1 },
                                        { label: '2', value: 2 },
                                        { label: '3', value: 3 },
                                        { label: '4', value: 4 },
                                        { label: '5+', value: 5 },
                                    ],
                                    fallback: 0,
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Drivers Licence',
                                    name: 'driver_license',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Drivers Licence Card Number',
                                    name: 'driver_license_card_number',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Drivers Licence State',
                                    name: 'driver_license_state',
                                    type: 'state',
                                    required: true,
                                },
                                {
                                    label: 'Drivers Licence Expiry',
                                    name: 'driver_license_expiry',
                                    type: 'date',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Citizenship Status',
                                    name: 'residency_status',
                                    type: 'select',
                                    options: [
                                        {
                                            value: 'citizen',
                                            label: 'Australia Citizen',
                                        },
                                        { value: 'non-resident', label: 'Non-Resident' },
                                        {
                                            value: 'permanent-resident',
                                            label: 'Permanent Resident',
                                        },
                                        { value: 'visa', label: 'VISA holder' },
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'Marital Status',
                                    name: 'marital_status',
                                    type: 'select',
                                    options: [
                                        { label: 'Single', value: 'single' },
                                        { label: 'De Facto', value: 'de-facto' },
                                        { label: 'Married', value: 'married' },
                                        { label: 'Separated', value: 'separated' },
                                    ],
                                    required: true,
                                },
                            ],
                        ],
                        validationSchema: {
                            personal: Yup.object({
                                title: Yup.string().required('Required'),
                                date_of_birth: Yup.string()
                                    .test('date_of_birth', 'You must be at least 17', (value) => {
                                        return moment().diff(moment(value), 'years') >= 17;
                                    })
                                    .nullable()
                                    .required('Required'),
                                gender: Yup.string().required('Required'),
                                first_name: Yup.string().required('Required'),
                                last_name: Yup.string().required('Required'),
                                residency_status: Yup.string().nullable().required('Required'),
                                driver_license: Yup.string().nullable().required('Required'),
                                driver_license_state: Yup.string().nullable().required('Required'),
                                driver_license_card_number: Yup.string().required('Required'),
                                driver_license_expiry: Yup.string()
                                    .test('driver_license_expiry', 'Must be in the future', (value) => {
                                        return moment(value).isSameOrAfter();
                                    })
                                    .nullable()
                                    .required('Required'),
                                marital_status: Yup.string().required('Required'),
                                dependents: Yup.number().integer().nullable(),
                            }),
                        },
                    },
                    {
                        title: 'Tell us where you live',
                        groupFieldName: 'personal[address]',
                        fields: [
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Current Home Address',
                                name: 'address_1',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Current Home Address Line 2',
                                name: 'address_2',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Suburb',
                                    name: 'suburb',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Postcode',
                                    name: 'postcode',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'State',
                                    name: 'state',
                                    type: 'state',
                                    required: true,
                                },
                                {
                                    label: 'Country',
                                    name: 'country_id',
                                    type: 'country',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Select type of residence',
                                    name: 'residential_status',
                                    type: 'select',
                                    groupFieldName: 'personal', // use this to override and prevent inheriting group field name
                                    options: [
                                        { label: 'Board', value: 'board' },
                                        { label: 'Buying/Mortgage', value: 'buying' },
                                        { label: 'Live With Parents', value: 'live-with-parents' },
                                        { label: 'Own Outright', value: 'own' },
                                        { label: 'Rent', value: 'rent' },
                                    ],
                                    required: true,
                                },
                                {
                                    groupFieldName: 'personal[address]',
                                    label: 'How many years have you been at this address',
                                    name: 'years',
                                    type: 'text',
                                    textType: 'number',
                                    required: true,
                                    min: 0,
                                },
                            ],
                            {
                                groupFieldName: 'personal[postal_address]',
                                label: 'Do you have a different postal address?',
                                name: 'has_different_postal',
                                type: 'boolean',
                            },
                        ],
                        validationSchema: {
                            personal: Yup.object({
                                address: Yup.object({
                                    address_detail_pid: Yup.string().nullable().required(pidRequiredMessage),
                                    address_1: Yup.string().nullable().required('Required'),
                                    suburb: Yup.string().nullable().required('Required'),
                                    postcode: Yup.string().nullable().required('Required'),
                                    state: Yup.string().nullable().required('Required'),
                                    years: Yup.number().nullable().required('Required'),
                                }),
                                residential_status: Yup.string().nullable().required('Required'),
                                postal_address: Yup.object({
                                    has_different_postal: Yup.boolean().required(),
                                }),
                            }),
                        },
                    },
                    {
                        title: 'Postal Address',
                        description: 'Please fill out your postal address',
                        groupFieldName: 'personal[postal_address]',
                        condition: {
                            field: 'personal[postal_address][has_different_postal]',
                            compare: '===',
                            value: true,
                        },
                        fields: [
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Postal Address',
                                name: 'address_1',
                                type: 'text',
                            },
                            {
                                label: 'Postal Address Line 2',
                                name: 'address_2',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Suburb',
                                    name: 'suburb',
                                    type: 'text',
                                },
                                {
                                    label: 'Postcode',
                                    name: 'postcode',
                                    type: 'text',
                                },
                            ],
                            [
                                {
                                    label: 'State',
                                    name: 'state',
                                    type: 'state',
                                },
                                {
                                    label: 'Country',
                                    name: 'country_id',
                                    type: 'country',
                                },
                            ],
                        ],
                        validationSchema: {
                            personal: Yup.object({
                                postal_address: Yup.object({
                                    address_detail_pid: Yup.string().required(pidRequiredMessage),
                                    address_1: Yup.string().required('Required'),
                                    suburb: Yup.string().required('Required'),
                                    postcode: Yup.string().required('Required'),
                                    state: Yup.string().required('Required'),
                                }),
                            }),
                        },
                    },
                    {
                        title: 'Previous Address',
                        description: 'Because you have been at your current address for less than 5 years, please supply your previous home address.',
                        groupFieldName: 'personal[previous_address]',
                        condition: {
                            field: 'personal.address.years',
                            compare: '<',
                            value: 5,
                        },
                        fields: [
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Previous Home Address',
                                name: 'address_1',
                                type: 'text',
                                validation: Yup.string().required('Required'),
                            },
                            {
                                label: 'Previous Home Address 2',
                                name: 'address_2',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Suburb',
                                    name: 'suburb',
                                    type: 'text',
                                    validation: Yup.string().required('Required'),
                                },
                                {
                                    label: 'Postcode',
                                    name: 'postcode',
                                    type: 'text',
                                },
                            ],
                            [
                                {
                                    label: 'State',
                                    name: 'state',
                                    type: 'state',
                                },
                                {
                                    label: 'Country',
                                    name: 'country_id',
                                    type: 'country',
                                },
                            ],
                            {
                                label: 'How many years were you at this address',
                                name: 'years',
                                type: 'text',
                                textType: 'number',
                                min: 0,
                            },
                        ],
                        validationSchema: {
                            personal: Yup.object({
                                previous_address: Yup.object({
                                    address_detail_pid: Yup.string().nullable().required(pidRequiredMessage),
                                    address_1: Yup.string().nullable().required('Required'),
                                    suburb: Yup.string().nullable().required('Required'),
                                    postcode: Yup.string().nullable().required('Required'),
                                    state: Yup.string().nullable().required('Required'),
                                    years: Yup.number().required('Required'),
                                }),
                            }),
                        },
                    },
                    {
                        title: 'Your contact details',
                        groupFieldName: 'personal',
                        fields: [
                            [
                                {
                                    label: 'Daytime Phone Number',
                                    name: 'phone',
                                    type: 'text',
                                    textType: 'tel',
                                    required: true,
                                },
                                {
                                    label: 'Mobile Phone Number',
                                    name: 'mobile',
                                    type: 'text',
                                    textType: 'tel',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Primary Email Address',
                                    name: 'email',
                                    type: 'text',
                                    textType: 'email',
                                    required: true,
                                },
                                {
                                    label: 'Alternative Email Address',
                                    name: 'email_2',
                                    type: 'text',
                                    textType: 'email',
                                },
                            ],
                        ],
                        validationSchema: {
                            personal: Yup.object({
                                phone: phoneValidation,
                                mobile: phoneValidation,
                                email: Yup.string().email('Please enter a valid email').required('Required'),
                                email_2: Yup.string().email('Please enter a valid email').nullable(),
                            }),
                        },
                    },
                ],
            },
            {
                title: 'Employment Details',
                groups: [
                    {
                        title: 'Your employment details',
                        groupFieldName: 'employment',
                        fields: [
                            [
                                {
                                    label: 'Employer',
                                    name: 'employer',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Employer phone number',
                                    name: 'phone',
                                    type: 'text',
                                    textType: 'tel',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Job title',
                                    name: 'job_title',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Length of employment (years)',
                                    name: 'length',
                                    type: 'text',
                                    textType: 'number',
                                    required: true,
                                    min: 0,
                                },
                            ],
                            [
                                {
                                    label: 'Employee number',
                                    name: 'employee_no',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Employment status',
                                    name: 'status',
                                    type: 'select',
                                    options: [
                                        {
                                            label: 'Full Time',
                                            value: 'Full Time',
                                        },
                                        {
                                            label: 'Part Time',
                                            value: 'Part Time',
                                        },
                                        {
                                            label: 'Casual',
                                            value: 'Casual',
                                        },
                                    ],
                                    required: true,
                                },
                            ],
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                groupFieldName: 'employment[address]',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Employer Address',
                                name: 'address_1',
                                groupFieldName: 'employment[address]',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Employer Address Line 2',
                                name: 'address_2',
                                groupFieldName: 'employment[address]',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Employer Suburb',
                                    name: 'suburb',
                                    groupFieldName: 'employment[address]',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Employer Postcode',
                                    name: 'postcode',
                                    groupFieldName: 'employment[address]',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Employer State',
                                    name: 'state',
                                    groupFieldName: 'employment[address]',
                                    type: 'state',
                                    required: true,
                                },
                                {
                                    label: 'Employer Country',
                                    name: 'country_id',
                                    groupFieldName: 'employment[address]',
                                    type: 'country',
                                    required: true,
                                },
                            ],
                        ],
                        validationSchema: {
                            employment: Yup.object({
                                length: Yup.number().required('Required'),
                                employee_no: Yup.string().required('Required'),
                                employer: Yup.string().required('Required'),
                                job_title: Yup.string().required('Required'),
                                status: Yup.string().required('Required'),
                                phone: phoneValidation,
                                address: Yup.object({
                                    address_detail_pid: Yup.string().required(pidRequiredMessage),
                                    address_1: Yup.string().required('Required'),
                                    suburb: Yup.string().required('Required'),
                                    postcode: Yup.string().required('Required'),
                                    state: Yup.string().required('Required'),
                                }).nullable(),
                            }).nullable(),
                        },
                    },
                    {
                        title: 'Your previous employment details',
                        description: 'Because you have been at your current employment for less than 5 years, please supply your previous employment',
                        groupFieldName: 'employment[previous]',
                        condition: {
                            field: 'employment.length',
                            compare: '<',
                            value: 5,
                        },
                        fields: [
                            [
                                {
                                    label: 'Previous Employer',
                                    name: 'employer',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Employer phone number',
                                    name: 'phone',
                                    type: 'text',
                                    textType: 'tel',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'Job title',
                                    name: 'job_title',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Length of employment (years)',
                                    name: 'length',
                                    type: 'text',
                                    textType: 'number',
                                    required: true,
                                    min: 0,
                                },
                            ],
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                groupFieldName: 'employment[previous][address]',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Employer Address',
                                name: 'address_1',
                                groupFieldName: 'employment[previous][address]',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Employer Address Line 2',
                                name: 'address_2',
                                groupFieldName: 'employment[previous][address]',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Suburb',
                                    name: 'suburb',
                                    groupFieldName: 'employment[previous][address]',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Postcode',
                                    name: 'postcode',
                                    groupFieldName: 'employment[previous][address]',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'State',
                                    name: 'state',
                                    groupFieldName: 'employment[previous][address]',
                                    type: 'state',
                                    required: true,
                                },
                                {
                                    label: 'Country',
                                    name: 'country_id',
                                    groupFieldName: 'employment[previous][address]',
                                    type: 'country',
                                    required: true,
                                },
                            ],
                        ],
                        validationSchema: {
                            employment: Yup.object({
                                previous: Yup.object({
                                    length: Yup.number().required('Required'),
                                    employer: Yup.string().required('Required'),
                                    job_title: Yup.string().required('Required'),
                                    phone: phoneValidation,
                                    address: Yup.object({
                                        address_detail_pid: Yup.string().required(pidRequiredMessage),
                                        address_1: Yup.string().required('Required'),
                                        suburb: Yup.string().required('Required'),
                                        postcode: Yup.string().required('Required'),
                                        state: Yup.string().required('Required'),
                                    }),
                                }).nullable(),
                            }).nullable(),
                        },
                    },
                ],
            },
            {
                title: 'References',
                groups: [
                    {
                        groupFieldName: 'references[one]',
                        title: 'Reference 1',
                        description: 'Must not be someone who resides at the same address as you. The reference will not be contacted during the application process.',
                        fields: [
                            [
                                {
                                    label: 'Full Name',
                                    name: 'name',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Phone Number',
                                    name: 'phone',
                                    type: 'text',
                                    textType: 'tel',
                                    required: true,
                                },
                            ],
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                groupFieldName: 'references[one][address]',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Address',
                                name: 'address_1',
                                groupFieldName: 'references[one][address]',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Address Line 2',
                                groupFieldName: 'references[one][address]',
                                name: 'address_2',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Suburb',
                                    groupFieldName: 'references[one][address]',
                                    name: 'suburb',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Postcode',
                                    groupFieldName: 'references[one][address]',
                                    name: 'postcode',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'State',
                                    groupFieldName: 'references[one][address]',
                                    name: 'state',
                                    type: 'state',
                                    required: true,
                                },
                                {
                                    label: 'Country',
                                    groupFieldName: 'references[one][address]',
                                    name: 'country_id',
                                    type: 'country',
                                    required: true,
                                },
                            ],
                        ],
                        validationSchema: {
                            references: Yup.object({
                                one: Yup.object({
                                    name: Yup.string().required('Required').nullable(),
                                    phone: phoneValidation,
                                    address: Yup.object({
                                        address_detail_pid: Yup.string().required(pidRequiredMessage).nullable(),
                                        address_1: Yup.string().required('Required').nullable(),
                                        suburb: Yup.string().required('Required').nullable(),
                                        state: Yup.string().required('Required').nullable(),
                                        postcode: Yup.string().required('Required').nullable(),
                                    }),
                                }),
                            }),
                        },
                    },
                    {
                        groupFieldName: 'references[two]',
                        name: 'references[one]',
                        title: 'Reference 2',
                        description: 'Must not be someone who resides at the same address as you. The reference will not be contacted during the application process.',
                        fields: [
                            [
                                {
                                    label: 'Full Name',
                                    name: 'name',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Phone Number',
                                    name: 'phone',
                                    type: 'text',
                                    textType: 'tel',
                                    required: true,
                                },
                            ],
                            {
                                label: 'Address PID',
                                name: 'address_detail_pid',
                                groupFieldName: 'references[two][address]',
                                type: 'pid',
                                required: true,
                            },
                            {
                                label: 'Address',
                                groupFieldName: 'references[two][address]',
                                name: 'address_1',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Address Line 2',
                                groupFieldName: 'references[two][address]',
                                name: 'address_2',
                                type: 'text',
                                maxLength: 30,
                            },
                            [
                                {
                                    label: 'Suburb',
                                    groupFieldName: 'references[two][address]',
                                    name: 'suburb',
                                    type: 'text',
                                    required: true,
                                },
                                {
                                    label: 'Postcode',
                                    groupFieldName: 'references[two][address]',
                                    name: 'postcode',
                                    type: 'text',
                                    required: true,
                                },
                            ],
                            [
                                {
                                    label: 'State',
                                    groupFieldName: 'references[two][address]',
                                    name: 'state',
                                    type: 'state',
                                    required: true,
                                },
                                {
                                    label: 'Country',
                                    groupFieldName: 'references[two][address]',
                                    name: 'country_id',
                                    type: 'country',
                                    required: true,
                                },
                            ],
                        ],
                        validationSchema: {
                            references: Yup.object({
                                two: Yup.object({
                                    name: Yup.string().required('Required').nullable(),
                                    phone: phoneValidation,
                                    address: Yup.object({
                                        address_detail_pid: Yup.string().required(pidRequiredMessage).nullable(),
                                        address_1: Yup.string().required('Required').nullable(),
                                        suburb: Yup.string().required('Required').nullable(),
                                        state: Yup.string().required('Required').nullable(),
                                        postcode: Yup.string().required('Required').nullable(),
                                    }),
                                }),
                            }),
                        },
                    },
                ],
            },
            {
                title: 'Income & Assets',
                groups: [
                    {
                        title: 'Net Income',
                        groupFieldName: 'financial[monthly_income]',
                        description: 'Please provide details as monthly, after-tax.',
                        fields: [
                            [
                                {
                                    label: 'Applicant’s monthly income',
                                    name: 'applicant',
                                    type: 'number',
                                    required: true,
                                },
                                {
                                    label: 'Spouse/partner/de facto monthly income',
                                    name: 'partner',
                                    type: 'number',
                                },
                            ],
                            {
                                label: 'Do you have any other monthly income?',
                                name: 'has_other',
                                type: 'boolean',
                            },
                            {
                                name: 'financial[monthly_income][other]',
                                type: 'repeater',
                                rowName: 'Other monthly income',
                                condition: {
                                    field: 'financial[monthly_income][has_other]',
                                    compare: '===',
                                    value: true,
                                },
                                fields: [
                                    [
                                        {
                                            label: 'Description',
                                            name: 'label',
                                            type: 'select',
                                            options: [
                                                {
                                                    label: 'Rental Income',
                                                    value: 'Rental Income',
                                                },
                                                {
                                                    label: 'Superannuation Income',
                                                    value: 'Superannuation Income',
                                                },
                                                {
                                                    label: 'Investment Income',
                                                    value: 'Investment Income',
                                                },
                                                {
                                                    label: 'Government Benefits',
                                                    value: 'Government Benefits',
                                                },
                                                { label: 'Pension', value: 'Pension' },
                                                { label: 'Other', value: 'Other' },
                                            ],
                                            required: true,
                                        },
                                        {
                                            label: 'Value',
                                            name: 'value',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                    {
                                        label: 'Please specify what type of income',
                                        name: 'label_other',
                                        type: 'text',
                                        required: true,
                                        condition: {
                                            field: 'financial[monthly_income][other][{{repeatIndex}}][label]',
                                            compare: 'string',
                                            value: 'Other',
                                        },
                                    },
                                ],
                                validationSchema: {
                                    financial: Yup.object({
                                        monthly_income: Yup.object({
                                            other: Yup.array().of(
                                                Yup.object().shape({
                                                    label: Yup.string().nullable().required('Required'),
                                                    label_other: Yup.string()
                                                        .nullable()
                                                        .when('label', {
                                                            is: 'Other',
                                                            then: (schema) => schema.required('Required'),
                                                        }),
                                                    value: Yup.number().required('Required'),
                                                })
                                            ),
                                        }),
                                    }),
                                },
                            },
                            {
                                type: 'total',
                                title: 'Your total monthly income',
                                name: 'total_income',
                                ignoreIfFalse: [
                                    {
                                        boolean: 'financial[monthly_income][has_other]',
                                        field: 'financial[monthly_income][other]',
                                    },
                                ],
                                fields: ['financial[monthly_income][applicant]', 'financial[monthly_income][partner]', 'financial[monthly_income][other]'],
                                property: 'value',
                            },
                        ],
                        validationSchema: {
                            financial: Yup.object({
                                monthly_income: Yup.object({
                                    applicant: Yup.number().required('Required'),
                                    partner: Yup.number().nullable(),
                                    has_other: Yup.boolean().required(),
                                }),
                            }),
                        },
                    },
                    {
                        title: 'Assets',
                        groupFieldName: 'assets',
                        fields: [
                            // assets.total
                            [
                                {
                                    label: 'Savings/Cash in bank',
                                    name: 'savings',
                                    type: 'number',
                                },
                                {
                                    label: 'Primary residence value',
                                    name: 'primary_residence',
                                    type: 'number',
                                },
                            ],
                            [
                                {
                                    label: 'Other house/land value',
                                    name: 'house_land',
                                    type: 'number',
                                },
                                {
                                    label: 'Motor vehicles',
                                    name: 'motor_vehicles',
                                    type: 'number',
                                },
                            ],
                            {
                                label: 'Household effects',
                                name: 'household',
                                type: 'number',
                            },
                            {
                                label: 'Do you have any other assets?*',
                                name: 'has_other',
                                type: 'boolean',
                            },
                            {
                                name: 'assets[other]',
                                type: 'repeater',
                                rowName: 'Other assets',
                                condition: {
                                    field: 'assets[has_other]',
                                    compare: '===',
                                    value: true,
                                },
                                fields: [
                                    [
                                        {
                                            label: 'Description',
                                            name: 'label',
                                            type: 'select',
                                            options: [
                                                {
                                                    label: 'Investment Property',
                                                    value: 'Investment Property',
                                                },
                                                { label: 'Shares', value: 'Shares' },
                                                { label: 'Superannuation', value: 'Superannuation' },
                                                { label: 'Recreational Investments', value: 'Recreational Investments' },
                                                { label: 'Plant and Equipment', value: 'Plant and Equipment' },
                                            ],
                                            required: true,
                                        },
                                        {
                                            label: 'Value',
                                            name: 'value',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                ],
                                validationSchema: {
                                    assets: Yup.object({
                                        other: Yup.array().of(
                                            Yup.object().shape({
                                                label: Yup.string().nullable().required('Required'),
                                                value: Yup.number().required('Required'),
                                            })
                                        ),
                                    }),
                                },
                            },
                            {
                                type: 'total',
                                title: 'Your total assets',
                                name: 'total_assets',
                                ignoreIfFalse: [
                                    {
                                        boolean: 'assets[has_other]',
                                        field: 'assets[other]',
                                    },
                                ],
                                fields: ['assets[savings]', 'assets[primary_residence]', 'assets[house_land]', 'assets[motor_vehicles]', 'assets[household]', 'assets[other]'],
                                property: 'value',
                            },
                        ],
                        validationSchema: {
                            assets: Yup.object({
                                has_other: Yup.boolean().required(),
                                savings: Yup.number().nullable(),
                                primary_residence: Yup.number().nullable(),
                                house_land: Yup.number().nullable(),
                                motor_vehicles: Yup.number().nullable(),
                                household: Yup.number().nullable(),
                            }),
                        },
                    },
                ],
            },
            {
                title: 'Expenses & Liabilities',
                groups: [
                    {
                        groupFieldName: 'liabilities',
                        fields: [
                            {
                                label: 'Do you pay rent?',
                                name: 'has_rent',
                                type: 'boolean',
                            },
                            {
                                groupFieldName: 'liabilities[rent][0]',
                                label: 'Monthly rent',
                                name: 'value',
                                type: 'number',
                                condition: {
                                    field: 'liabilities.has_rent',
                                    compare: '===',
                                    value: true,
                                },
                            },
                            {
                                label: 'Do you have any mortgages or investment loans?',
                                name: 'has_mortgages',
                                type: 'boolean',
                            },
                            {
                                type: 'repeater',
                                name: 'liabilities[mortgages]',
                                rowName: 'Other mortgages/loans',
                                condition: {
                                    field: 'liabilities.has_mortgages',
                                    compare: '===',
                                    value: true,
                                },
                                fields: [
                                    [
                                        {
                                            label: 'Financial institution',
                                            name: 'label',
                                            type: 'financier',
                                            required: true,
                                        },
                                        {
                                            label: 'Account type',
                                            name: 'type',
                                            type: 'select',
                                            options: [
                                                { label: 'Home', value: 'home' },
                                                { label: 'Investment', value: 'investment' },
                                            ],
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Balance owing',
                                            name: 'balance_owing',
                                            type: 'number',
                                            required: true,
                                        },
                                        {
                                            label: 'Minimum monthly repayment',
                                            name: 'value',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                ],
                                validationSchema: {
                                    liabilities: Yup.object({
                                        mortgages: Yup.array().of(
                                            Yup.object().shape({
                                                label: Yup.string().nullable().required('Required'),
                                                type: Yup.string().nullable().required('Required'),
                                                balance_owing: Yup.number().nullable(),
                                                value: Yup.number().nullable(),
                                            })
                                        ),
                                    }),
                                },
                            },
                        ],
                        validationSchema: {
                            liabilities: Yup.object({
                                has_rent: Yup.boolean().required(),
                                rent: Yup.array().when('has_rent', {
                                    is: (val) => {
                                        return val === true;
                                    },
                                    then: Yup.array().of(
                                        Yup.object({
                                            value: Yup.string().nullable().required('Required'),
                                        })
                                    ),
                                }),
                                has_mortgages: Yup.boolean().required(),
                                mortgages: Yup.array().when('has_mortgages', {
                                    is: (val) => {
                                        return val === true;
                                    },
                                    then: Yup.array().of(
                                        Yup.object({
                                            balance_owing: Yup.string().nullable().required('Required'),
                                        })
                                    ),
                                }),
                            }),
                        },
                    },
                    {
                        groupFieldName: 'liabilities',
                        fields: [
                            {
                                label: 'Do you have any credit cards?',
                                name: 'has_credit_cards',
                                type: 'boolean',
                            },
                            {
                                type: 'repeater',
                                rowName: 'Other credit cards',
                                name: 'liabilities[credit_cards]',
                                condition: {
                                    field: 'liabilities.has_credit_cards',
                                    compare: '===',
                                    value: true,
                                },
                                fields: [
                                    [
                                        {
                                            label: 'Financial institution',
                                            name: 'label',
                                            type: 'financier',
                                            required: true,
                                        },
                                        {
                                            label: 'Credit limit',
                                            name: 'credit_limit',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Balance owing',
                                            name: 'balance_owing',
                                            type: 'number',
                                            required: true,
                                        },
                                        {
                                            label: 'Minimum monthly repayment',
                                            name: 'value',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                ],
                                validationSchema: {
                                    liabilities: Yup.object({
                                        credit_cards: Yup.array().of(
                                            Yup.object().shape({
                                                label: Yup.string().nullable().required('Required'),
                                                credit_limit: Yup.number().nullable(),
                                                balance_owing: Yup.number().nullable(),
                                                value: Yup.number().nullable(),
                                            })
                                        ),
                                    }),
                                },
                            },
                        ],
                        validationSchema: {
                            liabilities: Yup.object({
                                has_credit_cards: Yup.boolean().required(),
                            }),
                        },
                    },
                    {
                        groupFieldName: 'liabilities',
                        fields: [
                            {
                                label: 'Do you have any other loans?',
                                name: 'has_loans',
                                type: 'boolean',
                            },
                            {
                                name: 'liabilities[loans]',
                                type: 'repeater',
                                rowName: 'Other loans',
                                condition: {
                                    field: 'liabilities.has_loans',
                                    compare: '===',
                                    value: true,
                                },
                                fields: [
                                    [
                                        {
                                            label: 'Type',
                                            name: 'type',
                                            type: 'select',
                                            options: [
                                                { label: 'Investment Loan', value: 'investment loan' },
                                                { label: 'Overdraft', value: 'overdraft' },
                                                { label: 'Recreational Asset Loans', value: 'recreational asset loans' },
                                                { label: 'Personal Loan', value: 'personal loan' },
                                                { label: 'Margin Loan', value: 'margin loan' },
                                                { label: 'Motor Vehicle Loan', value: 'motor vehicle loan' },
                                                { label: 'Plant and Equipment Loan', value: 'plant and equipment loan' },
                                                { label: 'Debt Agreement', value: 'debt agreement' },
                                                { label: 'Tax Debt', value: 'tax debt' },
                                            ],
                                            required: true,
                                        },
                                        {
                                            label: 'Financial institution',
                                            name: 'label',
                                            type: 'financier',
                                            condition: {
                                                field: 'liabilities[loans][{{repeatIndex}}][type]',
                                                compare: 'not_in',
                                                value: ['tax debt'],
                                            },
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Balance owing',
                                            name: 'balance_owing',
                                            type: 'number',
                                            required: true,
                                        },
                                        {
                                            label: 'Minimum monthly repayment',
                                            name: 'value',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'End date',
                                            name: 'end_date',
                                            type: 'date',
                                            condition: {
                                                field: 'liabilities[loans][{{repeatIndex}}][type]',
                                                compare: 'in',
                                                value: ['recreational asset loans', 'motor vehicle loan', 'plant and equipment loan'],
                                            },
                                            required: true,
                                        },
                                        {
                                            label: 'Credit Limit',
                                            name: 'credit_limit',
                                            type: 'number',
                                            condition: {
                                                field: 'liabilities[loans][{{repeatIndex}}][type]',
                                                compare: 'in',
                                                value: ['overdraft'],
                                            },
                                            required: true,
                                        },
                                    ],
                                ],
                                validationSchema: {
                                    liabilities: Yup.object({
                                        loans: Yup.array().of(
                                            Yup.object().shape({
                                                label: Yup.string().nullable().required('Required'),
                                                balance_owing: Yup.number().nullable(),
                                                value: Yup.number().nullable(),
                                                end_date: Yup.string().nullable(),
                                            })
                                        ),
                                    }),
                                },
                            },
                        ],
                        validationSchema: {
                            liabilities: Yup.object({
                                has_loans: Yup.boolean().required(),
                            }),
                        },
                    },
                    {
                        groupFieldName: 'liabilities[other]',
                        title: 'General living expenses',
                        description: 'The monthly value for your expenses',
                        fields: [
                            {
                                name: 'liabilities[other]',
                                type: 'repeater',
                                minRows: 4,
                                defaultRowValue: {
                                    label: '',
                                    value: 0,
                                },
                                fields: [
                                    [
                                        {
                                            label: 'Description',
                                            name: 'label',
                                            type: 'select',
                                            options: [
                                                {
                                                    label: 'Food',
                                                    value: 'Food',
                                                },
                                                {
                                                    label: 'Entertainment',
                                                    value: 'Entertainment',
                                                },
                                                {
                                                    label: 'Bills',
                                                    value: 'Bills',
                                                },
                                                {
                                                    label: 'Other',
                                                    value: 'Other',
                                                },
                                            ],
                                            required: true,
                                        },
                                        {
                                            label: 'Value',
                                            name: 'value',
                                            type: 'range',
                                            required: true,
                                        },
                                    ],
                                ],
                                validationSchema: {
                                    liabilities: Yup.object({
                                        other: Yup.array().of(
                                            Yup.object().shape({
                                                label: Yup.string().nullable().required('Required'),
                                                value: Yup.number().required('Required'),
                                            })
                                        ),
                                    }),
                                },
                            },
                            {
                                type: 'total',
                                title: 'Your total monthly general living expenses',
                                fields: ['liabilities[other]'],
                                property: 'value',
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Supporting Documents',
                groups: [
                    {
                        title: 'Visa Holders',
                        description: 'We require a copy of your VISA and current passport to verify your identity. Once verified this document will be removed.',
                        condition: {
                            field: 'personal[residency_status]',
                            compare: 'string',
                            value: 'VISA holder',
                        },
                        fields: [
                            {
                                label: 'A copy of your VISA document',
                                name: 'uploads_visa',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'A copy of your passport document',
                                name: 'uploads_passport',
                                type: 'file',
                                required: true,
                            },
                        ],
                        validationSchema: {
                            uploads_visa: Yup.string().required('Required').nullable(),
                            uploads_passport: Yup.string().required('Required').nullable(),
                        },
                    },
                    {
                        title: 'Driver’s Licence',
                        description: 'Please provide the front and back of your driver’s licence',
                        fields: [
                            {
                                label: 'Your driver’s licence (front)',
                                name: 'uploads_driver_license',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'Your driver’s licence (back)',
                                name: 'uploads_driver_license_back',
                                type: 'file',
                                required: true,
                            },
                        ],
                        validationSchema: {
                            uploads_driver_license: Yup.string().required('Required').nullable(),
                            uploads_driver_license_back: Yup.string().required('Required').nullable(),
                        },
                    },
                    {
                        title: 'Recent payslips',
                        description: 'Please include 3 recent payslips',
                        fields: [
                            {
                                label: 'A recent payslip',
                                name: 'uploads_recent_payslip',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'Recent payslip 2',
                                name: 'uploads_recent_payslip_2',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'Recent payslip 3',
                                name: 'uploads_recent_payslip_3',
                                type: 'file',
                                required: true,
                            },
                        ],
                        validationSchema: {
                            uploads_recent_payslip: Yup.string().required('Required').nullable(),
                            uploads_recent_payslip_2: Yup.string().required('Required').nullable(),
                            uploads_recent_payslip_3: Yup.string().required('Required').nullable(),
                        },
                    },
                    {
                        title: 'Recent Payslip (Other)',
                        description: 'Please provide 3 of your partner’s recent payslips if you have provided your partner’s income.',
                        condition: {
                            field: 'financial[monthly_income][partner]',
                            compare: '>',
                            value: 0,
                        },
                        fields: [
                            {
                                label: 'Your partner’s recent payslip',
                                name: 'uploads_recent_payslip_other',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'Your partner’s second recent payslip',
                                name: 'uploads_recent_payslip_other_2',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'Your partner’s third recent payslip',
                                name: 'uploads_recent_payslip_other_3',
                                type: 'file',
                                required: true,
                            },
                        ],
                        validationSchema: {
                            uploads_recent_payslip_other: Yup.string().required('Required').nullable(),
                            uploads_recent_payslip_other_2: Yup.string().required('Required').nullable(),
                            uploads_recent_payslip_other_3: Yup.string().required('Required').nullable(),
                        },
                    },
                    {
                        title: 'Monthly Income',
                        description: 'Please supply any supporting documents for income in the "Other monthly income" section',
                        condition: {
                            field: 'financial[monthly_income][has_other]',
                            compare: '===',
                            value: true,
                        },
                        fields: [
                            {
                                label: 'Income 1',
                                name: 'uploads_income',
                                type: 'file',
                                required: true,
                            },
                            {
                                label: 'Income 2',
                                name: 'uploads_income_2',
                                type: 'file',
                            },
                            {
                                label: 'Income 3',
                                name: 'uploads_income_3',
                                type: 'file',
                            },
                            {
                                label: 'Income 4',
                                name: 'uploads_income_4',
                                type: 'file',
                            },
                            {
                                label: 'Income 5',
                                name: 'uploads_income_5',
                                type: 'file',
                            },
                        ],
                        validationSchema: {
                            uploads_income: Yup.string().required('Required').nullable(),
                            uploads_income_2: Yup.string().nullable(),
                            uploads_income_3: Yup.string().nullable(),
                            uploads_income_4: Yup.string().nullable(),
                            uploads_income_5: Yup.string().nullable(),
                        },
                    },
                    {
                        title: 'Proof of address',
                        description: 'Council Rates Notice or Rental Agreement.',
                        fields: [
                            {
                                label: 'Please select the document',
                                name: 'uploads_residential',
                                type: 'file',
                                required: true,
                            },
                        ],
                        validationSchema: {
                            uploads_residential: Yup.string().required('Required').nullable(),
                        },
                    },
                ],
            },
        ],
    },
    {
        stage: 'Insurance Declaration',
        sections: [
            {
                title: 'The applicant',
                groups: [
                    {
                        groupFieldName: 'insurer',
                        title: 'Lessee details',
                        description: 'Registered Owner of the car',
                        fields: [
                            {
                                label: 'Full Name',
                                name: 'name',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Postcode',
                                name: 'postcode',
                                type: 'text',
                                required: true,
                            },
                            {
                                label: 'Employer',
                                name: 'employer',
                                type: 'text',
                                required: true,
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                name: Yup.string().required('Required').nullable(),
                                postcode: Yup.string().required('Required').nullable(),
                                employer: Yup.string().required('Required').nullable(),
                            }).nullable(),
                        },
                    },
                ],
            },
            {
                title: 'Drivers’ details',
                groups: [
                    {
                        groupFieldName: 'insurance',
                        fields: [
                            {
                                label: 'Drivers',
                                description: 'Drivers’ names (main driver first)',
                                name: 'insurer[drivers]',
                                type: 'repeater',
                                rowName: 'Driver',
                                fields: [
                                    {
                                        label: 'Full Name',
                                        name: 'name',
                                        type: 'text',
                                        required: true,
                                    },
                                    [
                                        {
                                            label: 'Date of birth',
                                            name: 'date_of_birth',
                                            type: 'date',
                                            required: true,
                                        },
                                        {
                                            label: 'Sex',
                                            name: 'sex',
                                            type: 'select',
                                            options: [
                                                { label: 'Male', value: 'male' },
                                                { label: 'Female', value: 'female' },
                                                { label: "I'd rather not say", value: '-' },
                                            ],
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Year drivers licence obtained in Australia',
                                            name: 'driver_since',
                                            type: 'text',
                                            textType: 'number',
                                            required: true,
                                            min: 0,
                                        },
                                        {
                                            label: '% use by driver (total 100%)',
                                            name: 'driver_percentage',
                                            type: 'text',
                                            textType: 'number',
                                            required: true,
                                            min: 0,
                                        },
                                    ],
                                ],
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                drivers: Yup.array().of(
                                    Yup.object({
                                        name: Yup.string().required('Required').nullable(),
                                        date_of_birth: Yup.string()
                                            .test('date_of_birth', 'You must be at least 17', (value) => {
                                                return moment().diff(moment(value), 'years') >= 17;
                                            })
                                            .nullable()
                                            .required('Required'),
                                        sex: Yup.string().required('Required').nullable(),
                                        driver_since: Yup.number().required('Required').positive().integer().nullable(),
                                        driver_percentage: Yup.number().min(0, 'Number greater than 0').max(100, 'Number less than or equal to 100').required('Required').nullable(),
                                    })
                                ),
                            }).nullable(),
                        },
                    },
                ],
            },
            {
                title: 'Driving history',
                groups: [
                    {
                        groupFieldName: 'insurer[history]',
                        descriptionLarge: true,
                        description: 'In the last 5 years, have you or any person likely to drive this car had:',
                        fields: [
                            {
                                label: 'A claim, accident or car stolen and/or burnt?',
                                name: 'claim',
                                type: 'boolean',
                            },
                            {
                                label: 'Insurance refused, declined or cancelled or special conditions imposed?',
                                name: 'insurance_cancelled',
                                type: 'boolean',
                            },
                            {
                                label: 'A drivers/motorcycle licence cancelled, suspended or endorsed?',
                                name: 'license_cancelled',
                                type: 'boolean',
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                history: Yup.object({
                                    claim: Yup.boolean().required(),
                                    insurance_cancelled: Yup.boolean().required(),
                                    license_cancelled: Yup.boolean().required(),
                                }),
                            }).nullable(),
                        },
                    },
                    {
                        groupFieldName: 'insurer[history]',
                        descriptionLarge: true,
                        description: 'In the last 5 years, have you or any person likely to drive this car been convicted with:',
                        fields: [
                            {
                                label: 'A drug use, driving under the influence or exceeding Prescribed Concentration of Alcohol?',
                                name: 'drug_use',
                                type: 'boolean',
                            },
                            {
                                label: 'Any driving offences or issued any speeding or traffic infringements?',
                                name: 'driving_offences',
                                type: 'boolean',
                            },
                            {
                                label: 'Fraud, arson theft or any other criminal act?',
                                name: 'criminal_act',
                                type: 'boolean',
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                history: Yup.object({
                                    drug_use: Yup.boolean().required(),
                                    driving_offences: Yup.boolean().required(),
                                    criminal_act: Yup.boolean().required(),
                                }),
                            }).nullable(),
                        },
                    },
                    {
                        title: 'Incidents',
                        description: 'Because you answered yes to one of the driver history questions above, please provide more details.',
                        groupFieldName: 'insurer[history][incidents]',
                        condition: {
                            fields: ['insurer[history].claim', 'insurer[history].insurance_cancelled', 'insurer[history].license_cancelled', 'insurer[history].drug_use', 'insurer[history].driving_offences', 'insurer[history].criminal_act'],
                            compare: '===',
                            value: true,
                        },
                        fields: [
                            {
                                name: 'insurer[history][incidents]',
                                type: 'repeater',
                                rowName: 'Incident',
                                rowNameAsTitle: true,
                                fields: [
                                    {
                                        label: 'Name of driver',
                                        name: 'driver',
                                        type: 'text',
                                        required: true,
                                    },
                                    [
                                        {
                                            label: 'Your insurer',
                                            name: 'insurer',
                                            type: 'text',
                                        },
                                        {
                                            label: 'Incident date',
                                            name: 'incident_date',
                                            type: 'date',
                                            required: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Person at fault',
                                            name: 'person_at_fault',
                                            type: 'text',
                                            required: true,
                                        },
                                        {
                                            label: 'Claim cost/penalty',
                                            name: 'value',
                                            type: 'number',
                                            required: true,
                                        },
                                    ],
                                    {
                                        label: 'Details of incident',
                                        name: 'details',
                                        type: 'textarea',
                                        required: true,
                                    },
                                ],
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                history: Yup.object({
                                    incidents: Yup.array().of(
                                        Yup.object({
                                            driver: Yup.string().required('Required').nullable(),
                                            details: Yup.string().required('Required').nullable(),
                                            value: Yup.number().required('Required').nullable(),
                                            person_at_fault: Yup.string().required('Required').nullable(),
                                            incident_date: Yup.string().required('Required').nullable(),
                                        })
                                    ),
                                }),
                            }).nullable(),
                        },
                    },
                    {
                        groupFieldName: 'insurer[history]',
                        fields: [
                            {
                                label: 'Suffered from any physical or mental disability that may affect your ability to drive (excluding eyesight corrected by lenses)',
                                name: 'disability',
                                type: 'boolean',
                            },
                            {
                                name: 'disability_details',
                                label: 'Please provide details',
                                type: 'textarea',
                                required: true,
                                condition: {
                                    fields: ['insurer[history].disability'],
                                    compare: '===',
                                    value: true,
                                },
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                history: Yup.object({
                                    disability: Yup.boolean().required(),
                                    disability_details: Yup.string()
                                        .when('disability', {
                                            is: (val) => {
                                                return val === true;
                                            },
                                            then: Yup.string().required('Required'),
                                        })
                                        .nullable(),
                                }),
                            }).nullable(),
                        },
                    },
                    {
                        groupFieldName: 'insurer[history]',
                        fields: [
                            {
                                label: 'Not held any car insurance in the past 12 months',
                                name: 'no_insurance',
                                type: 'boolean',
                            },
                            {
                                name: 'no_insurance_details',
                                label: 'Please provide details',
                                type: 'textarea',
                                required: true,
                                condition: {
                                    fields: ['insurer[history].no_insurance'],
                                    compare: '===',
                                    value: true,
                                },
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                history: Yup.object({
                                    no_insurance: Yup.boolean().required(),
                                    no_insurance_details: Yup.string()
                                        .when('no_insurance', {
                                            is: (val) => {
                                                return val === true;
                                            },
                                            then: Yup.string().required('Required'),
                                        })
                                        .nullable(),
                                }),
                            }).nullable(),
                        },
                    },
                ],
            },
            {
                title: 'Driver Verification',
                groups: [
                    {
                        groupFieldName: 'insurer[verification]',
                        descriptionLarge: true,
                        description: '',
                        fields: [
                            {
                                label: 'Are you in Permanent Gainful Employment? ',
                                name: 'permanent_employment',
                                type: 'boolean',
                            },
                            {
                                label: 'Are you an Australian or New Zealand Citizen or holder of any Australian or New Zealand permanent residency visa?',
                                name: 'permanent_residency',
                                type: 'boolean',
                            },
                            {
                                label: 'Do you have any prior knowledge that your Gainful Occupation is to be terminated when you apply for this insurance?',
                                name: 'prior_knowledge',
                                type: 'boolean',
                            },
                            {
                                label: 'Are you currently absent from your Gainful Occupation due to illness or injury, other than due to a minor illness such as cold or flu, when applying for this insurance?',
                                name: 'currently_absent',
                                type: 'boolean',
                            },
                            {
                                label: 'Are you currently self-employed or employed on a seasonal or temporary basis?',
                                name: 'currently_employed',
                                type: 'boolean',
                            },
                            {
                                label: 'Are you employed by a company controlled by You or Your Family with a shareholding in excess of 20%?',
                                name: 'company_owner',
                                type: 'boolean',
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                verification: Yup.object({
                                    permanent_employment: Yup.boolean().required(),
                                    permanent_residency: Yup.boolean().required(),
                                    prior_knowledge: Yup.boolean().required(),
                                    currently_absent: Yup.boolean().required(),
                                    currently_employed: Yup.boolean().required(),
                                    company_owner: Yup.boolean().required(),
                                }),
                            }).nullable(),
                        },
                    },
                ],
            },
            {
                title: 'Declaration',
                groups: [
                    {
                        groupFieldName: 'insurer',
                        fields: [
                            {
                                label: 'I acknowledge that I have read and agree to the above Terms and Conditions.',
                                name: 'agree',
                                type: 'checkbox',
                                mdContent: 'declaration',
                            },
                        ],
                        validationSchema: {
                            insurer: Yup.object({
                                agree: Yup.bool().oneOf([true], 'You must agree to the terms and conditions'),
                            }).nullable(),
                        },
                    },
                ],
            },
        ],
    },
    {
        stage: 'Privacy & Consent',
        sections: [
            {
                title: 'Applicant details and agreement',
                groups: [
                    {
                        title: 'Applicant',
                        fields: [
                            {
                                label: 'Full Name',
                                name: 'financier[applicants]',
                                type: 'repeater',
                                maxRows: 1,
                                fields: [
                                    {
                                        label: 'Full name*',
                                        name: 'name',
                                        type: 'text',
                                    },
                                    {
                                        label: 'I acknowledge that I have read and agree to the above Terms and Conditions.',
                                        name: 'agree',
                                        type: 'checkbox',
                                        mdContent: 'financier',
                                    },
                                ],
                            },
                        ],
                        validationSchema: {
                            financier: Yup.object({
                                applicants: Yup.array().of(
                                    Yup.object({
                                        agree: Yup.bool().oneOf([true], 'You must agree to the terms and conditions'),
                                    })
                                ),
                            }),
                        },
                    },
                ],
            },
        ],
    },
    {
        stage: 'Submission',
        submission: true,
    },
];
